<template>
  <Breadcrumb :items="breadcrumbs"/>
  <h1 class="title title--main title--page mb-3 text-center">{{ t('forgot_password') }}</h1>
  <form class="form-in" @submit.prevent="send" @keydown="form.onKeydown($event)">
    <div v-if="status" class="alert alert-success">{{ status }}</div>
    <img src="@/assets/img/form-bg.png" class="form-in__bg">
    <div class="form-group">
      <div class="form-label">{{ t('email') }}</div>
      <input v-model="form.email" type="email" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }" >
      <div v-if="form.errors.has('email')" v-html="form.errors.get('email')" class="invalid-feedback" />
    </div>
    <br>
    <div class="text-center">
      <button class="btn btn--blue" type="submit">{{ t('send_password_reset_link') }}</button>
    </div>
  </form>
</template>
<script>
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { ref } from '@vue/runtime-core'
import Form from 'vform'
export default {
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('signin')
    })
    const status = ref('')
    const form = ref(new Form({
      email: ''
    }))
    // methods
    async function send () {
      const { data } = await form.value.post(process.env.VUE_APP_API_URL + '/password/email')
      status.value = data.status
      form.value.reset()
    }
    return {
      t,
      status,
      form,
      send,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('forgot_password'), href: null, active: true }
      ]
    }
  }
}
</script>
